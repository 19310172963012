@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.chatWrapperEnduser {
  text-align: right;
  margin-top: 5px;
  margin-bottom: 5px;
  animation: fadeIn 1s;
}

.messageWrapperEnduser {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  width: fit-content;
  max-width: 80%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 15px;
  margin-left: auto;
  margin-right: 0;
  text-align: left;

  &.topBorder {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 2px;
    border-top-left-radius: 10px;
  }
  
  &.bottomBorder {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  &.topBorder.bottomBorder{
    border-top-right-radius: 2px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 10px;
  }
}

.chatWrapperAgent {
  max-width: 80%;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: fadeIn 1s;
}

.chatAgentName {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.messageWrapperAgent {
  width: fit-content;
  max-width: 80%;
  border-radius: 0 8px 8px 8px;
  background-color: #f5f5f5;
  color: #000000;
  padding: 10px 15px;

  &.topBorder {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 2px;
  }
  
  &.bottomBorder {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  &.topBorder.bottomBorder{
    border-top-right-radius: 10px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 2px;
  }
}

.timestamp {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 6px;
}

.messageContent {
  margin: 0;
  line-height: 18px;
  overflow-wrap: break-word;
  white-space: pre-line;
  text-align: left;
}

.chatWrapper {
  display: flex;
  flex-flow: column;
}

.textSize20 {
  font-size: 20px;
}

.sameRowTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.connectionError {
  composes: orangeTextColor from "../styles/HomePage.module.css";
  composes: textSize12 from "../styles/HomePage.module.css";
  display: block;
  height: 18px;
  width: 100%;
  text-align: center;
}
