.sameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.sameColumn {
  display: flex;
  flex-direction: column;
}

.iconSpace {
  margin-right: 10px;
  width: 24px;
}

.rightSpace {
  margin-right: 5px;
}

.leftSpace {
  margin-left: 5px;
}

.textSize10 {
  font-size: 10px;
}

.textSize12 {
  font-size: 12px;
}

.textSize16 {
  font-size: 16px;
}

.textSize20 {
  font-size: 20px;
}

.interestedNumber {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: right;
  color: #00294d;
}

.borderBottomBorder {
  background-color: #dadde4;
  margin-top: -10px;
  margin-bottom: 17px;
}

.checkboxLabel {
  margin-right: 10px;
  accent-color: #0077b3;
}

.topTitleSpace {
  margin-top: 20px;
}

.bottomSpace {
  margin-bottom: 10px;
}

.bottomTitleSpace {
  margin-bottom: 20px;
}

.uploadedImg {
  cursor: pointer;
}

.licenseBorder {
  border: 2px solid #000;
  border-radius: 4px;
  width: fit-content;
  padding: 0px 10px;
}

.separateLine {
  background-color: DADDE4;
  margin: 10px 0px;
}

.addButton {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.addButtonImg {
  border-radius: 8px;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  cursor: pointer;
}

.iconCenter {
  position: relative;
  top: calc(50% - 12px); /* 50% - 3/4 of icon height */
  left: calc(50% - 12px);
}

.imageTitle {
  text-align: center;
  font-size: 10px;
  line-height: 22px;
}

.licensePlate {
  margin-right: 10px;
}

.licensePlateDemo {
  margin-right: 10px;
  margin-left: -13px;
}

.uploadFileWrapper {
  width: 100%;
  border: 1px dashed #000000;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.uploadButton {
  background-color: none;
}

.centerWrapper {
  width: 100%;
  text-align: center;
}

.borderColor {
  border: 1px solid #0077b3;
  border-radius: 8px;
}

.licensePlateCenter {
  display: block;
  text-align: center;
  margin-bottom: 24px;
}

.useTradeInVehicleBtn {
  border: none;
  background-color: transparent;
  color: #00294d;
  text-decoration: underline;
}

.orangeTextColor {
  color: #d67300;
}

.centerText {
  text-align: center;
}

.rightText {
  text-align: right;
}

.notificationText {
  color: #616265;
}

.notificationWrapper {
  background-color: #0077b3;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 5px;
  text-align: center;
  margin-bottom: 20px;
}

.notificationContent {
  padding: 0px 5px;
}

.offerWrapper {
  background-color: #ffffff;
  border: 1px solid #0077b3;
  box-shadow: 0px 4px 4px #4695f1;
  border-radius: 8px;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 20px;
}

.loginWrapper {
  text-align: center;
}

.breakLine {
  width: 90%;
  border: 1px solid #dadde4;
}

.cartDeleteBtn {
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: -40px;
}

.haftOpacity {
  opacity: 0.5;
}

.coverOpacity {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
  z-index: 1000;
}

.soldOut {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  opacity: 1;
}

.soldOutWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.infoIconTitle {
  margin-top: -15px;
  margin-left: 5px;
}

.textSpace {
  margin-top: -10px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 428px;
  box-shadow: 24;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  padding: 20px 20px;
}

.modalIcon {
  width: 68px;
  height: 68px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.uppercaseText {
  text-transform: uppercase;
}

.buttonCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.disabledText {
  color: rgba(0, 0, 0, 0.38);
}

.linkBtnActive {
  color: #ffffff;
  text-decoration: none;
}

.linkBtn {
  text-decoration: none;
}

.errorText {
  color: #de2362;
}

.sameRowTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.saveDealBtnPosition {
  position: fixed;
  right: 10px;
  bottom: 0%;
  opacity: 1;
  width: 95%;
  text-align: right;
}

.localeSwitcherPosition {
  margin-top: 8px;
  margin-bottom: 16px;
}

.newCarImg {
  width: 100%;
  height: auto;
}

.pickedTradeInVehicleWrapper {
  background-color: #4695f1;
  border-radius: 8px;
  color: #ffffff;
}

.whiteText {
  color: #ffffff;
}

.tradeinCardWrapper > div:not(:last-child) {
  margin-bottom: 12px;
}

.tradeinCardWrapper:last-child {
  padding-bottom: 16px;
}
