.carSummary {
  height: fit-content;
  padding: 0;
}

.financingRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

.startingFromText {
  font-size: 14px;
  margin-right: 4px;
}

.montlyPriceText {
  font-size: 18px;
  font-weight: bold;
}

.carPriceText {
  font-size: 13px;
  position: relative;
  bottom: 4px;
  margin-left: 5px;
}

.carDetails {
  padding: 0;
  margin-top: 8px;
}

.productIntro {
  color: #616265;
}

.infoDivider {
  margin: 8px 0;
}

.infoChipContainer {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
}

.infoChip {
  margin-right: 8px;
  margin-bottom: 8px;
}

.carLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
