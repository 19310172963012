.HomePage_sameRow__IIP4K {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.HomePage_sameColumn__O4rXh {
  display: flex;
  flex-direction: column;
}

.HomePage_iconSpace__vPIAD {
  margin-right: 10px;
  width: 24px;
}

.HomePage_rightSpace__pE6y5 {
  margin-right: 5px;
}

.HomePage_leftSpace__O7mGJ {
  margin-left: 5px;
}

.HomePage_textSize10__eQW8_ {
  font-size: 10px;
}

.HomePage_textSize12__KyYbv {
  font-size: 12px;
}

.HomePage_textSize16__L8_HY {
  font-size: 16px;
}

.HomePage_textSize20__3TAG6 {
  font-size: 20px;
}

.HomePage_interestedNumber__YlEOj {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: right;
  color: #00294d;
}

.HomePage_borderBottomBorder__BRXNO {
  background-color: #dadde4;
  margin-top: -10px;
  margin-bottom: 17px;
}

.HomePage_checkboxLabel__eNxad {
  margin-right: 10px;
  accent-color: #0077b3;
}

.HomePage_topTitleSpace__0_gwn {
  margin-top: 20px;
}

.HomePage_bottomSpace__vAyac {
  margin-bottom: 10px;
}

.HomePage_bottomTitleSpace__R46eN {
  margin-bottom: 20px;
}

.HomePage_uploadedImg__sAWQ7 {
  cursor: pointer;
}

.HomePage_licenseBorder__MOY3N {
  border: 2px solid #000;
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 10px;
}

.HomePage_separateLine__myhpg {
  background-color: DADDE4;
  margin: 10px 0px;
}

.HomePage_addButton__TWy7r {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.HomePage_addButtonImg__V_uOH {
  border-radius: 8px;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  cursor: pointer;
}

.HomePage_iconCenter__JmUui {
  position: relative;
  top: calc(50% - 12px); /* 50% - 3/4 of icon height */
  left: calc(50% - 12px);
}

.HomePage_imageTitle__yTwzd {
  text-align: center;
  font-size: 10px;
  line-height: 22px;
}

.HomePage_licensePlate__F5gnm {
  margin-right: 10px;
}

.HomePage_licensePlateDemo__LqZlc {
  margin-right: 10px;
  margin-left: -13px;
}

.HomePage_uploadFileWrapper__Jop6T {
  width: 100%;
  border: 1px dashed #000000;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.HomePage_uploadButton___wH7x {
  background-color: none;
}

.HomePage_centerWrapper__iI0Wo {
  width: 100%;
  text-align: center;
}

.HomePage_borderColor__lY8vL {
  border: 1px solid #0077b3;
  border-radius: 8px;
}

.HomePage_licensePlateCenter__prKpt {
  display: block;
  text-align: center;
  margin-bottom: 24px;
}

.HomePage_useTradeInVehicleBtn__tjsb_ {
  border: none;
  background-color: transparent;
  color: #00294d;
  text-decoration: underline;
}

.HomePage_orangeTextColor__yJKEr {
  color: #d67300;
}

.HomePage_centerText__MUsam {
  text-align: center;
}

.HomePage_rightText__vhxSI {
  text-align: right;
}

.HomePage_notificationText__Rzy0G {
  color: #616265;
}

.HomePage_notificationWrapper__yqsUX {
  background-color: #0077b3;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 5px;
  text-align: center;
  margin-bottom: 20px;
}

.HomePage_notificationContent__r69QF {
  padding: 0px 5px;
}

.HomePage_offerWrapper__Og9U_ {
  background-color: #ffffff;
  border: 1px solid #0077b3;
  box-shadow: 0px 4px 4px #4695f1;
  border-radius: 8px;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 20px;
}

.HomePage_loginWrapper__apJkZ {
  text-align: center;
}

.HomePage_breakLine__7F1Ky {
  width: 90%;
  border: 1px solid #dadde4;
}

.HomePage_cartDeleteBtn__Nop32 {
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: -40px;
}

.HomePage_haftOpacity__8oQM6 {
  opacity: 0.5;
}

.HomePage_coverOpacity__f4FaV {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
  z-index: 1000;
}

.HomePage_soldOut__JHp6S {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  opacity: 1;
}

.HomePage_soldOutWrapper__CX42t {
  position: relative;
  width: 100%;
  height: 100%;
}

.HomePage_infoIconTitle__5sspW {
  margin-top: -15px;
  margin-left: 5px;
}

.HomePage_textSpace__mKjyE {
  margin-top: -10px;
}

.HomePage_modal__NXmWK {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 428px;
  box-shadow: 24;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  padding: 20px 20px;
}

.HomePage_modalIcon__rvpCL {
  width: 68px;
  height: 68px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.HomePage_uppercaseText__WjZVe {
  text-transform: uppercase;
}

.HomePage_buttonCenter__1U3_p {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.HomePage_disabledText__OXvq_ {
  color: rgba(0, 0, 0, 0.38);
}

.HomePage_linkBtnActive__XPbR7 {
  color: #ffffff;
  text-decoration: none;
}

.HomePage_linkBtn__IfPnS {
  text-decoration: none;
}

.HomePage_errorText__bPrFO {
  color: #de2362;
}

.HomePage_sameRowTitle__3bQkm {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.HomePage_saveDealBtnPosition__fX67D {
  position: fixed;
  right: 10px;
  bottom: 0%;
  opacity: 1;
  width: 95%;
  text-align: right;
}

.HomePage_localeSwitcherPosition___km83 {
  margin-top: 8px;
  margin-bottom: 16px;
}

.HomePage_newCarImg__rL9yC {
  width: 100%;
  height: auto;
}

.HomePage_pickedTradeInVehicleWrapper__tn5Vc {
  background-color: #4695f1;
  border-radius: 8px;
  color: #ffffff;
}

.HomePage_whiteText__dqnNL {
  color: #ffffff;
}

.HomePage_tradeinCardWrapper__RyXfL > div:not(:last-child) {
  margin-bottom: 12px;
}

.HomePage_tradeinCardWrapper__RyXfL:last-child {
  padding-bottom: 16px;
}

.CarInfo_carSummary__23L8t {
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
}

.CarInfo_financingRow__DkAIe {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

.CarInfo_startingFromText__ZfMwB {
  font-size: 14px;
  margin-right: 4px;
}

.CarInfo_montlyPriceText__I8BIP {
  font-size: 18px;
  font-weight: bold;
}

.CarInfo_carPriceText__zozK0 {
  font-size: 13px;
  position: relative;
  bottom: 4px;
  margin-left: 5px;
}

.CarInfo_carDetails__GK_Dd {
  padding: 0;
  margin-top: 8px;
}

.CarInfo_productIntro__II9Mf {
  color: #616265;
}

.CarInfo_infoDivider__yr_XW {
  margin: 8px 0;
}

.CarInfo_infoChipContainer__mSiuE {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
}

.CarInfo_infoChip__Xb_eZ {
  margin-right: 8px;
  margin-bottom: 8px;
}

.CarInfo_carLink__u5Jzf {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

@keyframes ChattingWithDealer_fadeIn__jrM2W {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ChattingWithDealer_chatWrapperEnduser__f1orz {
  text-align: right;
  margin-top: 5px;
  margin-bottom: 5px;
  animation: ChattingWithDealer_fadeIn__jrM2W 1s;
}

.ChattingWithDealer_messageWrapperEnduser__VSXZJ {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 15px;
  margin-left: auto;
  margin-right: 0;
  text-align: left;

  &.ChattingWithDealer_topBorder__w9Hqt {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 2px;
    border-top-left-radius: 10px;
  }
  
  &.ChattingWithDealer_bottomBorder__kdW16 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  &.ChattingWithDealer_topBorder__w9Hqt.ChattingWithDealer_bottomBorder__kdW16{
    border-top-right-radius: 2px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 10px;
  }
}

.ChattingWithDealer_chatWrapperAgent___JGtu {
  max-width: 80%;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: ChattingWithDealer_fadeIn__jrM2W 1s;
}

.ChattingWithDealer_chatAgentName__TdjR6 {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.ChattingWithDealer_messageWrapperAgent__SGiwn {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
  border-radius: 0 8px 8px 8px;
  background-color: #f5f5f5;
  color: #000000;
  padding: 10px 15px;

  &.ChattingWithDealer_topBorder__w9Hqt {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 2px;
  }
  
  &.ChattingWithDealer_bottomBorder__kdW16 {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  &.ChattingWithDealer_topBorder__w9Hqt.ChattingWithDealer_bottomBorder__kdW16{
    border-top-right-radius: 10px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 2px;
  }
}

.ChattingWithDealer_timestamp__e2q6R {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 6px;
}

.ChattingWithDealer_messageContent__WNfSW {
  margin: 0;
  line-height: 18px;
  overflow-wrap: break-word;
  white-space: pre-line;
  text-align: left;
}

.ChattingWithDealer_chatWrapper__5HkWu {
  display: flex;
  flex-flow: column;
}

.ChattingWithDealer_textSize20__xph96 {
  font-size: 20px;
}

.ChattingWithDealer_sameRowTitle__Dn1hx {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.ChattingWithDealer_connectionError__DG8zj {
  display: block;
  height: 18px;
  width: 100%;
  text-align: center;
}

.Instructions_buttonContainer__WdhmN {
  display: flex;
  margin-top: 10px;
}

.SingleDeal_dealCard__hz0wX:not(:last-child) {
  margin-bottom: 12px;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ac0efabfe978b0ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7d1684f14ddac155-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/82233a533941ac93-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0881a2b922b3331e-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/994bf73bb06543dc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/279b47070a5d5877-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/045832894acda0e9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/483de911b1a0d258-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0e5e1c6a8db9e432-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5693677ef07d9b51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28aa5118b38b86e4-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8720059dfa14a1fe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df6186c8d69644-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/120a5a1920781bd0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/27971e35634b7c88-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/cd31bf4b34f8dfb3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ecf49d904668b268-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2f66f084fba01545-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/906678b269849541-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/674abd25bb7be96f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/da897b99eb1fe4a1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/80b1a0e600ca6d83-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7c16c8204ab29534-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f756da832d8c34d4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/98a28a5430a3cf7f-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df2942b6de9d14b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7a7012758df5a81e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_d891f1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6ebb97b5c9fa4e03-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_d891f1';src: local("Arial");ascent-override: 92.98%;descent-override: 24.47%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_d891f1 {font-family: '__Roboto_d891f1', '__Roboto_Fallback_d891f1';font-style: normal
}

.ConfirmationDialog_modal__J4gFd {
  width: "100%";
}

.ConfirmationDialog_container__3s13F {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 428px;
  box-shadow: 24;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  padding: 20px 20px;
}

.ConfirmationDialog_title__hmPOB {
  margin-bottom: 0;
}

.ConfirmationDialog_description__rnRcu {
  padding: 16px;
}

.ConfirmationDialog_declineBtn__sn0XM {
  min-width: 112px;
  margin-right: 8px;
}

.ConfirmationDialog_acceptBtn__h8HrR {
  min-width: 112px;
}

