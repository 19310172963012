.modal {
  width: "100%";
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 428px;
  box-shadow: 24;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  padding: 20px 20px;
}

.title {
  margin-bottom: 0;
}

.description {
  padding: 16px;
}

.declineBtn {
  min-width: 112px;
  margin-right: 8px;
}

.acceptBtn {
  min-width: 112px;
}
